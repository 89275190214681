<template>
  <el-container id="app">

    <el-header style="height: auto;">
      <el-row>
        <el-col :xs="24" :md="9">
          <img src="./assets/siix-piix-logo.png" class="logo" />
        </el-col>
        <el-col :xs="24" :md="15">
          <div class="links">
            <el-link :underline="false" :href="discordUrl"><i class="fab fa-discord"></i></el-link>
            <el-link :underline="false" :href="twitterUrl"><i class="fab fa-twitter"></i></el-link>
            <el-button type="info" v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet">Sync</el-button>
            <el-button type="info" v-else @click="disconnectWallet">Unsync
              <span v-if="wallet.connected" style="margin-left: 5px;">{{ `${wallet.pkh.substr(0, 6)}...${wallet.pkh.substr(-6)}` }}</span>
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-header>

    <el-main>

      <el-row :gutter="30" style="margin-bottom: 90px">
        <el-col :xs="24" :md="12" style="text-align: center;">
          <img src="./assets/examples.gif" />
        </el-col>
        <el-col :xs="24" :md="12" class="mint" style="text-align: center;">
          <el-row :gutter="30" style="text-align: center; margin-top: 24px;">
            <el-col :xs="24" :md="12" style="padding: 15px;">
              <el-button type="info" :disabled="!isMinting || numMinted >= maxMint" @click="mint(1)">MINT 1</el-button>
            </el-col>
            <el-col :xs="24" :md="12" style="padding: 15px;">
              <el-button type="info" :disabled="!isMinting || numMinted >= maxMint" @click="mint(2)">MINT 2</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="text-align: center;">
            <el-col :xs="24" :md="12" style="padding: 15px;">
              <el-button type="info" :disabled="!isMinting || numMinted >= maxMint" @click="mint(3)">MINT 3</el-button>
            </el-col>
            <el-col :xs="24" :md="12" style="padding: 15px;">
              <el-button type="info" :disabled="!isMinting || numMinted >= maxMint" @click="mint(4)">MINT 4</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="text-align: center;">
            <el-col :xs="24" :md="12" style="padding: 15px;">
              <el-button type="info" :disabled="!isMinting || numMinted >= maxMint" @click="mint(5)">MINT 5</el-button>
            </el-col>
            <el-col :xs="24" :md="12" style="padding: 15px;">
              <el-button type="info" :disabled="!isMinting || numMinted >= maxMint" @click="mint(6)">MINT 6</el-button>
            </el-col>
          </el-row>
          <el-progress :show-text="false" status="success" :stroke-width="40" :percentage="parseInt((numMinted / maxMint) * 100)" style="margin-top: 24px;"></el-progress>
          <span v-if="!isMinting" class="sale-status">Minting soon!</span>
          <span v-else-if="numMinted >= maxMint" class="sale-status">Sold out</span>
          <span v-else class="sale-status">{{ numMinted }}/{{ maxMint }} sold</span>
        </el-col>
      </el-row>

      <el-card>
        <div slot="header" class="clearfix">
          <span>FAQ</span>
        </div>
        <div class="card-body faq">
          <el-row :gutter="30" style="margin-top: 30px;">
            <el-col :xs="24" :md="12">
              <h2>What is the mint price?</h2>
              <p>With our low mint price of <strong>6 XTZ</strong> it ensures any artist or collector can take part in the project and join the community.</p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>Where can I trade my Piixies?</h2>
              <p>Piixies will have their own collection on OBJKT.COM and can be traded from there.</p>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 30px;">
            <el-col :xs="24" :md="12">
              <h2>How many Piixies are there?</h2>
              <p>There will be 1,000 available to mint with a million unique combinations.</p>
            </el-col>
            <el-col :xs="24" :md="12">
              <h2>When is release?</h2>
              <p>Whitelist mint will be on February 7th, with Public mint being the 8th.</p>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="margin-top: 30px;">
            <el-col :span="24">
              <h2>How will you ensure fair distribution?</h2>
              <p>The contract includes a provenance hash. This is a SHA-256 hash of an IPFS directory that refers to a fingerprint of the tokens and the art. It means anyone can verify that we didn’t change the tokens after minting them. Also, when the sale starts, the id of each mint is randomized.</p>
            </el-col>
            <el-col :span="24">
              <h2>Provenance &amp; Contracts</h2>
              <p v-if="crowdSaleContract">Crowdsale Contract:<br/><a :href="`https://better-call.dev/mainnet/${crowdSaleContract}/operations`">{{ crowdSaleContract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
              <p v-else>Crowdsale Contract:<br/><strong>Coming Soon!</strong></p>
              <p v-if="fa2Contract">Piixie NFT Contract:<br/><a :href="`https://better-call.dev/mainnet/${fa2Contract}/operations`">{{ fa2Contract }} <i class="far fa-external-link" style="margin-left: 8px"></i></a></p>
              <p v-else>Piixie NFT Contract:<br/><strong>Coming Soon!</strong></p>
              <p v-if="provenanceHash">Provenance Hash:<br/><strong>{{ provenanceHash }}</strong></p>
              <p v-else>Provenance Hash:<br/><strong>Coming Soon!</strong></p>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card>
        <div slot="header" class="clearfix">
          <span>Roadmap</span>
        </div>
        <div class="card-body">
          <ol>
            <li>White List Launches On February 7th - Public Mint Goes Live February 8th</li>
            <li>10% Revenue Will Go To An Art Fund To Collect From Our Community</li>
            <li>Holders On Our Discord Will Be Entered Into Giveaways And Airdrops</li>
            <li>Once The Project Has Sold Out We Will Launch A Virtual Gallery Showcasing The Art We Collected</li>
            <li>After Sell-Out More Information Will Be Revealed About Season 2 - Which Only Holders Can Mint</li>
          </ol>
          <p>Thank You &lt;3</p>
        </div>
      </el-card>

      <div class="team">
        <h1>Meet The Team</h1>
        <el-row :gutter="30" style="margin-bottom: 40px">
          <el-col :xs="24" :md="8" style="text-align: center;">
            <el-avatar :size="180" :src="avatar1"></el-avatar>
            <h2>CodeCrafting</h2>
            <p>Tezos web3 and smart contract developer</p>
          </el-col>
          <el-col :xs="24" :md="8" style="text-align: center;">
            <el-avatar :size="180" :src="avatar2"></el-avatar>
            <h2>Siix</h2>
            <p>Creator and founder of the project</p>
          </el-col>
          <el-col :xs="24" :md="8" style="text-align: center;">
            <el-avatar :size="180" :src="avatar3"></el-avatar>
            <h2>Covie Bien</h2>
            <p>Co-Artist &amp; Designer for all siix projects</p>
          </el-col>
        </el-row>
      </div>

    </el-main>

    <!-- <el-footer>
    </el-footer> -->

  </el-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getWalletContract } from './utils/tezos';
import tzkt from './utils/tzkt';

const avatar1 = require('./assets/avatar-1.png');
const avatar2 = require('./assets/avatar-2.png');
const avatar3 = require('./assets/avatar-3.png');

export default {
  name: 'app',
  data() {
    return {
      avatar1,
      avatar2,
      avatar3,
      discordUrl: process.env.VUE_APP_DISCORD_URL,
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      objktUrl: process.env.VUE_APP_OBJKT_URL,
      crowdSaleContract: process.env.VUE_APP_CROWDSALE_CONTRACT,
      fa2Contract: process.env.VUE_APP_FA2_CONTRACT,
      provenanceHash: process.env.VUE_APP_PROVENANCE_HASH,
      whitelistStartTime: new Date('2022-02-07T19:00:00Z'),
      whitelistEndTime: new Date('2022-02-08T19:00:00Z'),
      saleStartTime: new Date('2022-02-08T19:00:00Z').getTime(),
      numMinted: 0,
      maxMint: 1000,
      mintOpPending: false,
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    isWhitelist: function() {
      const now = new Date();
      return (now >= this.whitelistStartTime && now < this.whitelistEndTime);
    },

    isMinting: function() {
      return this.isWhitelist || (new Date() >= this.saleStartTime);
    }
  },
  created() {
    this.updateMinted();
  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    async updateMinted() {
      const storage = await tzkt.getContractStorage(this.crowdSaleContract);
      this.numMinted = parseInt(storage.data.sale.soldAmount);
      if (this.numMinted < this.maxMint) {
        const vm = this;
        setTimeout(vm.updateMinted, 15000);
      }
    },

    async mint(mintNum) {
      const amount = parseInt(mintNum * (this.isWhitelist ? 5000000 : 6000000));
      if (mintNum < 1 || amount < 1) {
          return;
      }

      try {
        await this.connectWallet();
        const op = await (await getWalletContract(process.env.VUE_APP_CROWDSALE_CONTRACT))
          .methods
          .mint(mintNum)
          .send({
            amount: amount,
            mutez: true,
            storageLimit: 3000
          });
        this.mintOpPending = true;
        await op.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.mintOpPending = false;
    }
  }
}
</script>

<style lang="scss">
@import "custom-variables.scss";

html,
body {
  margin: 0;
  height: 100%;
}

body {
  font-family: 'Press Start 2P', cursive;
  background: #D1C1EF;
  color: #000;
}

button {
  font-family: 'Press Start 2P', cursive;
}

#app {
  max-width: 1280px;
  margin: 0 auto;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 24px;
  
    a {
      color: $--color-success;
    }
  }

  ol {
    margin: 10px 50px;
    margin-bottom: 50px;

    li {
      margin-bottom: 18px;
    }
  }

  .el-header {
    margin-top: 90px;
    margin-bottom: 90px;

    .logo {
      max-width: 100%
    }

    .links {
      float: right;
      margin-top: 0px;

      a,
      a:visited {
        display: block;
        margin-top: 6px;
        float: left;
        font-size: 28px;
        margin-right: 24px;
        line-height: 32px;
        color: #000;

        &:hover {
          color: $--color-primary;
        }
      }
    }
  }


  .el-button {
    border-radius: 10px;
    border: 3px solid #000;
    box-shadow: 5px 5px 0 #000;
    padding: 20px 40px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .el-button--info.is-disabled {
    background: #ccc;
    color: #666;
    border-color: #666;
    box-shadow: 5px 5px 0 #666;
  }

  .el-button--info {
    color: #000;
  }

  .mint {
    .el-button {
      padding: 30px 10px;
      width: 100%;
    }
  }

  .el-progress-bar__outer {
    border-radius: 10px;
    border: 3px solid #000;
    box-shadow: 5px 5px 0 #000;

    .el-progress-bar__inner {
      border-radius: 0;
    }
  }

  .el-card {
    border-radius: 10px;
    border: 5px solid #000;
    box-shadow: 15px 15px 0 #000;
    background: #f4f4f4;
    margin-bottom: 90px;

    .el-card__header {
      border-bottom: 5px solid #000;
      padding: 24px 20px;
      text-align: center;
      position: relative;
      text-transform: uppercase;
      font-size: 2.5em;
    }
  }

  .sale-status {
    display: block;
    margin-top: 24px;
  }

  .faq h2 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 12px;
    color: #105D80;
    text-transform: capitalize;
  }
  .faq p {
    font-size: 14px;
  }
  .faq strong {
    color: #179658;
  }

  .team {
    margin-top: 120px;

    h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 2.5em;
      margin-top: 90px;
      margin-bottom: 90px;
    }

    h2 {
      font-size: 1.25em;
      margin-top: 24px;
    }

    p {
      font-size: 0.75em;
    }

    .el-col {
      margin-bottom: 50px;
    }
  }
}


.el-avatar {
    background: transparent;
}

@media only screen and (max-width: 991px) {

}

</style>
